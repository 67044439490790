import api from 'shared/api'

export const loadQuestions = async ({
  accessToken,
  page = 1,
  limit = 10,
  questionId,
}) => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get(`/public/questions/${questionId}/answers`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      page,
      limit,
    },
  })

  return { list, total }
}

export const loadComments = async ({ id, page, accessToken }) => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get('/comments', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      reference: id,
      type: 'Answer',
      page,
      limit: 10,
    },
  })
  return {
    list,
    total,
  }
}

export const deleteComment = async ({ accessToken, id }) => {
  await api.delete(`/comments/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}
export const deleteQuestion = async ({ accessToken, id }) => {
  await api.delete(`/questions/answers/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const publishQuestion = ({
  text,
  createAsStaff,
  questionId,
  accessToken,
}) => {
  return api.post(
    `/questions/${questionId}/answers`,
    {
      text,
      createAsStaff,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}
export const postVote = ({ accessToken, id, vote }) => {
  return api.post(`/questions/answers/${id}/vote?vote=${vote}`, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
export const removeVote = ({ accessToken, id }) => {
  return api.delete(`/questions/answers/${id}/vote`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const publishComment = ({ text, id, accessToken }) => {
  return api.post(
    '/comments',
    {
      text,
      type: 'Answer',
      reference: id,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}
export const updateComment = ({ text, id, accessToken }) => {
  return api.put(
    `/comments/${id}`,
    {
      text,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}
export const publishAnswer = ({ text, id, accessToken }) => {
  return api.post(
    `/ask-me-questions/questions/${id}/answer`,
    {
      text,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const completeAMQ = ({ amqId, accessToken }) => {
  return api.patch(`/ask-me-questions/${amqId}/mark-as-completed`, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const deleteAMQ = ({ questionId, accessToken }) => {
  return api.delete(`/questions/${questionId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const postQuestionVote = ({ accessToken, id, vote }) => {
  return api.post(`/questions/${id}/vote?vote=${vote}`, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
export const removeQuestionVote = ({ accessToken, id }) => {
  return api.delete(`/questions/${id}/vote`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
