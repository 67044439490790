import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'
// import { MdArrowCircleUp, MdArrowCircleDown } from 'react-icons/md'
import { BiUpvote, BiDownvote, BiLink } from 'react-icons/bi'
import { MdPictureAsPdf } from 'react-icons/md'
import { Tag } from 'components/elements'

import { postQuestionVote, removeQuestionVote } from '../modules/datasource'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 152px;
  height: fit-content;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px;
  }
`
export const Content = styled.div`
  flex: 1;
  max-width: 704px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  .tox-tinymce {
    border: 0;
  }
`
export const QuestionsContainer = styled(Container)`
  background: ${({ theme }) => theme.colors.lightGrey};
  flex: 1;
`

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 24px;
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`

export const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`

export const AuthorImg = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
`

export const AMQBadge = styled.div`
  padding: 0 8px;
  background-color: ${({ theme }) => theme.colors.accentY};
  margin-right: 12px;
  ${({ completed }) =>
    completed &&
    css`
      background-color: ${({ theme }) => theme.colors.grey2};
    `}
`

export const PublishDate = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`

export const AMQTitle = styled.div`
  ${({ theme }) => theme.font.h3};
  margin-bottom: 24px;
`
export const AMQDescription = styled.div`
  ${({ theme }) => theme.font.body};
  margin-bottom: 24px;
  white-space: pre-wrap;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`
export const AMQBio = styled.div`
  ${({ theme }) => theme.font.body2};
  color: ${({ theme }) => theme.colors.grey};
  span {
    color: ${({ theme }) => theme.colors.black};
    line-height: 28px;
  }
  margin-bottom: 24px;
`
export const QuestionContent = styled.div`
  display: flex;
`
export const AMQFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`

export const MarkCompleteButton = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`
const VoteContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  transition: all 0.2s ease-in-out;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
const UpvoteIcon = styled(BiUpvote)`
  width: 26px;
  height: 26px;
  max-width: 26px;
  max-height: 26px;
  min-width: 26px;
  min-height: 26px;
`
const DownvoteIcon = styled(BiDownvote)`
  width: 26px;
  height: 26px;
  max-width: 26px;
  max-height: 26px;
  min-width: 26px;
  min-height: 26px;
`
const Upvote = ({
  accessToken,
  id,
  setCrrVotes,
  myVote,
  setMyVote,
  isCompleted,
}) => {
  const crrActive = myVote === 'Upvote'
  const handleVote = async () => {
    if (crrActive && accessToken) {
      await removeQuestionVote({ accessToken, id })
      setCrrVotes(R.subtract(R.__, 1))
      setMyVote(null)
      return
    }
    await postQuestionVote({ accessToken, id, vote: 'Upvote' })
    setCrrVotes(R.add(myVote === 'Downvote' ? 2 : 1))
    setMyVote('Upvote')
  }

  return (
    <VoteContainer
      onClick={handleVote}
      active={crrActive}
      disabled={!accessToken || isCompleted}
    >
      <UpvoteIcon />
    </VoteContainer>
  )
}
const Downvote = ({
  accessToken,
  id,
  setCrrVotes,
  myVote,
  setMyVote,
  isCompleted,
}) => {
  const crrActive = myVote === 'Downvote'
  const handleVote = async () => {
    if (crrActive && accessToken) {
      await removeQuestionVote({ accessToken, id })
      setCrrVotes(R.add(1))
      setMyVote(null)
      return
    }
    await postQuestionVote({ accessToken, id, vote: 'Downvote' })
    setCrrVotes(R.subtract(R.__, myVote === 'Upvote' ? 2 : 1))
    setMyVote('Downvote')
  }
  return (
    <VoteContainer
      onClick={handleVote}
      active={crrActive}
      disabled={!accessToken || isCompleted}
    >
      <DownvoteIcon />
    </VoteContainer>
  )
}
const VotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 14px;
`

export const Votes = ({ crrVotes, ...voteConfig }) => {
  return (
    <VotesContainer>
      <Upvote {...voteConfig} />
      {crrVotes || 0}
      <Downvote {...voteConfig} />
    </VotesContainer>
  )
}

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  padding-bottom: 36px;
`
export const TagItem = styled(Tag)`
  margin: 4px;
  padding: 12px;
  cursor: pointer;
`

export const ReferenceTitle = styled.div`
  ${({ theme }) => theme.font.h3}
`

export const RefList = styled.ul`
  padding-inline-start: 0;
  padding-bottom: 36px;
`
export const ListItem = styled.li`
  display: flex;
  align-items: center;
`
export const ItemDesc = styled.a`
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: solid 1px ${({ theme }) => theme.colors.black};
  padding: 4px 0;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const LinkIcon = styled(BiLink)`
  font-size: 22px;
  margin-right: 10px;
`
export const PdfIcon = styled(MdPictureAsPdf)`
  font-size: 22px;
  margin-right: 10px;
`
